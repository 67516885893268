export function getHref(element: {
  groupHandle?: string;
  sectionHandle?: string;
  slug?: string;
	uri?: string;
	baseUrl?: string;
}, baseUrl?: string): string {
  if (!element) return `/`;

  const handle = element.sectionHandle || element.groupHandle;

  switch (handle) {
    case "pressIndex":
      return `/about/news`;
    case "press":
      return `/about/news/${element.slug}`;

    case "newsTypes":
      return `/about/news/category/${element.slug}`;
    
    case "attackIndex":
      return `/attacks`;

    case "blogIndex":
      return `/blog`;
    case "blog":
      return `/blog/${element.slug}`;

    case "glossaryIndex":
      return `/glossary`;
    case "glossary":
      return `/glossary/${element.slug}`;

    case "blogCategories":
      return `/blog/category/${element.slug}`;
    case "postAuthors":
      return `/blog/author/${element.slug}`;

    case "releaseNoteIndexOld":
      return `/release-notes`;
    case "releaseNotesOld":
      return `/release-notes/${element.slug}`;

    case "resourceIndex":
      return `/resources`;
    case "resources":
			return `/resources/${element.slug}`;
		
    case "tourIndex":
      return `/tours`;
    case "tours":
			return `/tours/${element.slug}`;
		
		case "partnerPortal":
      return `/partner-portal/${element.slug}`;

    case "resourceTypes":
      return `${!!baseUrl ? baseUrl : "/resources"}/category/${element.slug}`;
    case "topics":
      return `${!!baseUrl ? baseUrl : "/resources"}/category/${element.slug}`;
    case "industries":
			return `${!!baseUrl ? baseUrl : "/resources"}/category/${element.slug}`;
		case "country":
			return `${!!baseUrl ? baseUrl : "/resources"}/category/${element.slug}`;
		case "customer":
      return `${!!baseUrl ? baseUrl : "/resources"}/category/${element.slug}`;

    case "eventIndex":
      return `/about/events`;
    case "events":
      return `/about/events/${element.slug}`;

    case "eventTypes":
      return `/about/events/category/${element.slug}`;

    case "teamIndex":
      return `/about/team`;
    case "profiles":
      return `/about/team/${element.slug}`;
    case "profileTypes":
      return `/about/team/category/${element.slug}`;

    case "contact":
      return `/contact`;

    case "pages":
      return `/${element.uri}`;

    case "demo":
      return `/demo`;

    default:
      return `/`;
  }
}

export default getHref;
